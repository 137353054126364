import React from 'react';
import {STYLES} from "../App";

const About = () => {
    return (
    <div>
        <div className={STYLES.cards}>
            <h4>Для получения консультации и дополнительной информации напишите нам</h4>
            <ul>
                <li>
                    <a href={'https://t.me/vilebone'} target={'_blank'} rel={'noreferrer'}>Telegram</a>
                </li>
                <li>
                    <a href={'https://wa.me/79122493713?text=У меня вопрос по услугам flippium'} target={'_blank'} rel={'noreferrer'}>WhatsApp</a>
                </li>
            </ul>
            </div>
    </div>
        );
};

export default About;